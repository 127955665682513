import React from 'react'

const Avatar = ({ avatar, ...props }) => {
  return (
    <div
      css={{
        color: avatar.color,
        maxWidth: avatar.size + avatar.outerBorderWidth + avatar.padding + 'px',
        backgroundColor: avatar.bg,
        height: 'auto',
        width: 'auto',
        //overflow: 'hidden',
        borderWidth: avatar.outerBorderWidth + 'px',
        borderStyle: avatar.outerBorderStyle,
        borderColor: 'currentColor',
        boxShadow: avatar.boxShadow,
        padding: avatar.padding + 'px',
        borderRadius: `${avatar.outerTopRightRadius}% ${avatar.outerTopLeftRadius}% ${avatar.outerBottomRightRadius}% ${avatar.outerBottomLeftRadius}% / ${avatar.outerMirrorTopRightRadius}% ${avatar.outerMirrorTopLeftRadius}% ${avatar.outerMirrorBottomRightRadius}% ${avatar.outerMirrorBottomLeftRadius}%`
      }}
      {...props}
    >
      <img
        src={props.image}
        css={{
          position: 'relative',
          width: avatar.size + 'px',
          height: avatar.size + 'px',
          display: 'block',
          borderWidth: avatar.borderWidth + 'px',
          borderStyle: avatar.borderStyle,
          borderColor: avatar.borderColor,
          borderRadius: `${avatar.innerTopRightRadius}% ${avatar.innerTopLeftRadius}% ${avatar.innerBottomRightRadius}% ${avatar.innerBottomLeftRadius}% / ${avatar.innerMirrorTopRightRadius}% ${avatar.innerMirrorTopLeftRadius}% ${avatar.innerMirrorBottomRightRadius}% ${avatar.innerMirrorBottomLeftRadius}%`
        }}
      />
    </div>
  )
}

Avatar.defaultProps = {}

export default Avatar
