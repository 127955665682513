import React from 'react'
import Div from '../elements/Div'
import Article from '../elements/Article'
import H3 from '../elements/H3'
import Img from '../elements/Img'
import P from '../elements/P'
import Span from '../elements/Span'

const Card = ({ title, text, image, border, ...props }) => {
  return (
    <Article {...props}>
      <Div borderTop={border ? '4px solid' : 'none'}>
        <Img src={image} />
        <H3>{title}</H3>
        <P>{text}</P>
      </Div>
    </Article>
  )
}

Card.defaultProps = {
  px: 3,
  width: [1, 1 / 3],
  border: true
}

export default Card
