import React from 'react'
import Div from '../elements/Div'

const Container = ({ ...props }) => {
  return (
    <Div {...props}>
      <Div maxWidth="72rem" mx="auto">
        {props.children}
      </Div>
    </Div>
  )
}

Container.defaultProps = {}

export default Container
