import styled from '@emotion/styled'
import {
  space,
  width,
  display,
  fontSize,
  lineHeight,
  textAlign,
  color,
  borders,
  borderColor
} from 'styled-system'

const Cite = styled('cite')(
  space,
  width,
  display,
  fontSize,
  lineHeight,
  textAlign,
  color,
  borders,
  borderColor,
  {}
)

Cite.defaultProps = {}

export default Cite
