import styled from '@emotion/styled'
import {
  space,
  width,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  borders,
  borderColor,
  borderRadius,
  color
} from 'styled-system'

const InputTelephone = styled('input')(
  space,
  width,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  color,
  borders,
  borderColor,
  borderRadius,
  { boxSizing: 'border-box' }
)

InputTelephone.defaultProps = {
  type: 'tel'
}

export default InputTelephone
