/** @jsx jsx */
import jsx from '../jsx'
import React, { useState } from 'react'
import { Styled } from 'theme-ui'

import { db } from '../lib/client'

export default () => {
  const [email, setEmail] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  return (
    <section
      py={[5, 5, 7]}
      px={[4, 5, 6]}
      maxWidth="100%"
      style={{ overflow: 'hidden' }}
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          db.collection('newsletterSignups').add({
            email
          })

          setSubmitted(true)
        }}
      >
        <h4
          fontSize={[1, 2, 3]}
          lineHeight={1.25}
          textAlign="center"
          mx="auto"
          mt={0}
          mb={[3, 4]}
          style={{ textAlign: 'center' }}
        >
          Stay up to date on the latest in generative design
        </h4>
        <div
          display="flex"
          maxWidth="32em"
          mx="auto"
          px={[0, 3]}
          justifyContent="center"
        >
          <div
            width={1}
            position="relative"
            bg="white"
            display="flex"
            alignItems="stretch"
          >
            <input
              fontSize={[1, 2]}
              width={1}
              px={2}
              py={[2, 3]}
              border="0px solid"
              borderColor="indigo.5"
              lineHeight="1.0"
              required={true}
              value={email}
              type="email"
              height="100%"
              style={{
                appearance: 'none',
                WebkitAppearance: 'none'
              }}
              onChange={e => setEmail(e.target.value)}
            />
            <div width={128} style={{ flexShrink: 0 }}>
              <div
                position="absolute"
                right="0"
                top="0"
                bottom="0"
                bg="indigo.5"
                width={128}
              >
                <button
                  mt={0}
                  mb={0}
                  width={1}
                  fontSize={1}
                  border="0"
                  height="100%"
                  bg="indigo.5"
                  type="submit"
                  fontWeight={600}
                  display="flex"
                  justifyContent="center"
                  disabled={submitted}
                  style={{
                    WebkitAppearance: 'none',
                    appearance: 'none'
                  }}
                >
                  {submitted ? 'Thank you!' : 'Sign Up'}
                </button>
              </div>
            </div>
          </div>
        </div>
        {submitted ? (
          <p mx="auto" textAlign="center" fontSize={1} color="#555">
            Thank you for signing up! You will be sent a confirmation email
            shortly.
          </p>
        ) : (
          <p fontSize={0} my={2} color="black" mx="auto" textAlign="center">
            Your email will never be used for spam. Ever.
          </p>
        )}
      </form>
    </section>
  )
}
