import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import {
  space,
  width,
  height,
  maxWidth,
  display,
  alignItems,
  justifyContent,
  fontSize,
  fontWeight,
  lineHeight,
  textAlign,
  textStyle,
  color,
  borders,
  borderColor,
  borderRadius
} from 'styled-system'

const A = styled('a')(
  space,
  width,
  height,
  maxWidth,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  textAlign,
  textStyle,
  color,
  alignItems,
  justifyContent,
  borders,
  borderColor,
  borderRadius,
  {
    boxSizing: 'border-box',
    overflow: 'hidden',
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'opacity .2s ease-in',
    ':hover': {
      opacity: 0.7,
      transition: 'opacity .2s ease-in'
    }
  }
)

A.defaultProps = {
  display: 'inline-block',
  bg: 'transparent',
  color: 'black',
  cursor: 'pointer',
  href: '#0'
}

export default A
