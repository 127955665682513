/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'

import theme from '../theme'
import Logo from '../components/Logo'
import Avatar from '../components/Avatar'
import Flex from '../components/Flex'
import Container from '../components/Container'
import Layout from '../components/layout'
import Card from '../components/Card'
import NewsletterSignup from '../components/newsletter-signup'

export default () => {
  return (
    <Layout>
      <h1>Acorn</h1>
    </Layout>
  )
}
