import styled from '@emotion/styled'
import {
  space,
  width,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  borders,
  borderColor,
  borderRadius,
  color
} from 'styled-system'

const InputRadio = styled('input')(
  space,
  width,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  color,
  borders,
  borderColor,
  borderRadius,
  {}
)

InputRadio.defaultProps = {
  type: 'radio'
}

export default InputRadio
